/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import './variables/_variables.scss';


@each $color_name, $color in $colors2 {
  @each $color_type in $color {
    // background color
    .bg-#{$color_name} {
      background-color: $color_type !important;
    }
  }
}

.fallback-logo {
  max-width: 200px;
  position: absolute;
  left: calc(50% - 100px);
  top: 40%;
}
.auth-wrapper {

a.brand-logo img {
  max-width: calc(100% - 50px);
}
}
.z-index-0 {
  z-index: 0 !important;
}

/*
  _navigation.scss
 ******************/
.main-menu {
  .navbar-header {
    .navbar-brand {
      .brand-logo {
        img {
          width: 100%;
          max-width: 120px;
        }
      }

      .brand-text {
        font-style: italic;
        display: none;
      }
    }
  }

  .navigation {
    li {
      a {
        svg {
          width: 20px;
          height: 20px;
          min-width: 25px;
        }
      }
    }
  }
}
.main-menu:not(.expanded) {
  .navbar-header {
    .navbar-brand {
      .brand-logo {
        img {
          width: 100%;
          max-width: 50px;
        }
      }
    }
  }
}


/*
  END OF _navigation.scss
 **************************/

/*
  app-loader.scss
 */
.loading {
  .effect-1,
  .effect-2 {
    border-left: 3px solid $primary;
  }

  .effect-3 {
    border-left: 3px solid $primary;
  }
}

/*
  END OF app-loader.scss
 **************************/

/*
   _pagination.scss
 **************************/
.page-item:nth-child(2) {
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;

  .page-link {
    border-top-left-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important;
  }
}

.page-item:nth-last-child(2) {
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;

  .page-link {
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important;
  }
}

.page-link {
  z-index: 0 !important;
}

/*
   END OF _pagination.scss
 **************************/

@media(max-width: 450px) {
  .w-sm-150px {
    width: 150px
  }
}

.w-sm-150px {
  width: 150px
}

/*
   FileUploader Thumbnails
 **************************/
.dropzone {
  display: flex;
  cursor: pointer;
  min-height: 300px;
  align-items: center;
  justify-content: center;
  background-color: $body-bg;
  border: 1px dashed $primary;
  border-radius: $border-radius;
}


// Avatar Group SCSS
#avatarGroupPortal {
  .avatar-group {
    .avatar {
      .avatar-content {
        background-color: inherit !important;
      }
    }
  }
}

// Forms.scss

.form-check-input {
  width: 1.285rem;
  height: 1.285rem;

  &:checked {
    background-color: $primary;
    border-color: $primary;
    accent-color: $primary;
  }

  &:not(:disabled) {
    &:checked {
      box-shadow: 0 2px 4px 0 rgba($primary, 0.4);
    }

    &:indeterminate {
      background-size: 85%;
    }
  }
}

// _card.scss

.card-statistics .statistics-body {
  padding: 2rem 1.5rem 2.8rem !important;
}


// app-invoice.css Mobile Styles

@media (max-width: 767px) {
  .search-invoice-wrapper {
    width: 100%;

    #search-invoice {
      width: 100%
    }
  }
  .cdev-selector-status {
    width: 100%;
  }
}

// ripple-button.scss
.waves-effect {
  .waves-ripple {
    pointer-events: none;
  }
}

.react-select {
  &__multi-value__label {
    color: white !important;
  }

  &__option {
    display: block;
    padding: 11px 12px;
    width: 100%;
    user-select: none;
    box-sizing: border-box;

    &:hover {
      background-color: #EBECF0;
    }

    &_selected {
      background-color: #42526E;
      color: #FFFFFF;

      &:hover {
        background-color: #42526E;
      }
    }
  }
}

button.btn.btn-action-address  {
  gap: 5px;
  display: flex;
  padding: 3px 10px;
  align-items: center;
  @media screen and (max-width: 735px) {
   gap: 0;
   font-size: 0;
   margin: 0 auto;
  }
}

@media screen and (max-width: 435px) {
  .container-btn {
    gap: 5px !important;
    button.btn {
      margin: 0 !important;
    }
  }
}


