//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Body
//
// Settings for the `<body>` element.
$body-bg: #f8f8f8 !default;
$body-color: #6e6b7b !default;

/*
Colors para Vicsa
*/
$primary: #0058db;
//$primary: #ef9b11; vicsa
//$bg-2: #ef9b11;
//$bg-3: #d3760c;
//$bg-4: #f5bc2a;
//$bg-5: #d4760b;
//$bg-6: #b0530d;
//$bg-7: #fdb813;
//$bg-8: #ffe1a1;
//$bg-9: #383838;
//$bg-10: #6a6d6d;
//
//$colors2: (
//        'bg-1': $bg-2,
//        'bg-2': $bg-3,
//        'bg-3': $bg-4,
//        'bg-4': $bg-5,
//        'bg-5': $bg-6,
//        'bg-6': $bg-7,
//        'bg-7': $bg-8,
//        'bg-8': $bg-9,
//        'bg-9': $bg-10,
//);



// vertical menu
$menu-expanded-width: 320px !default;

// scss-docs-start border-radius-variables
$border-radius: .25rem !default;
$border-radius-sm: .2rem !default;
$border-radius-lg: .3rem !default;
$border-radius-pill: 50rem !default;
// scss-docs-end border-radius-variables

// Tables
$table-head-bg: rgba(0, 47, 134, 0.12) !default;


/*
Colors para Espomega
*/
//$primary: #002f86;
$bg-2: #062f87;
$bg-3: #2a6eff;
$bg-4: #7bc5ea;
$bg-5: #16c5c3;
$bg-6: #d76f2c;
$bg-7: #fdb813;
$bg-8: #ffe1a1;
$bg-9: #383838;
$bg-10: #6a6d6d;

$colors2: (
        'bg-1': $bg-2,
        'bg-2': $bg-3,
        'bg-3': $bg-4,
        'bg-4': $bg-5,
        'bg-5': $bg-6,
        'bg-6': $bg-7,
        'bg-7': $bg-8,
        'bg-8': $bg-9,
        'bg-9': $bg-10,
);
