//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

// Custom Radio
.custom-radio {
  .custom-control-label {
    cursor: pointer;
  }
}

.custom-control {
  z-index: 0;
}

.wrapper.vertical-layout.navbar-floating.footer-static.vertical-menu-modern.menu-collapsed h2.brand-text.mb-0 {
  display: none;
}